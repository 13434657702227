<template>
    <main>
       <div class="">
           <div class="card-header bg-light">Tanner</div>
           <div class="card-body">
            <div class="row" v-for="item in tanner_estadio" :key="item.id">
                <div class="col-12">
                <h6>{{item.estadio.tipo.nombre}}</h6>
               <table class="table table-bordered table-sm font-size-12">
                   <tr>
                       <th>Tanner</th>
                       <th>Descripcion</th>
                    </tr>
                   <tr>
                       <td class="text-center align-middle">{{item.estadio.estadio}}</td>
                       <td class="align-middle">{{item.estadio.descripcion}}</td>
                   </tr>
               </table>
                </div>
               </div>
            </div>
        </div>
    </main>
</template>
<script>
import tannerValoracionService from "../../../../services/tannerValoracionService";  
export default {
    props: ['idHistoria','finalizada', 'sexo'],
    data(){
        return {
            tanner_estadio:[],
        }
    },
    methods: {
        async cargarTanners(){
            const responseValoracion = await tannerValoracionService.showByHc(this.idHistoria);
            this.tanner_estadio = responseValoracion.data;
        },
    },
    
   async created (){
        await this.cargarTanners();
        
        
    }
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>